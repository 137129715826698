<template>
  <div
    class="checkout-broker"
    :class="`checkout-broker-${checkoutType}`"
    style="width: 100%"
    :style="`background-color:${backgroundColor};`"
  >
    <component :is="header" />
    <v-form
      ref="broker-form"
      v-model="formIsValid"
      class="checkout-provider-payment-form-credit-card"
      @submit.prevent="submit"
    >
      <v-container justify-center fluid style="max-width: 1200px">
        <v-layout row justify-center class="my-4">
          <v-flex shrink style="text-align: center">
            <h1>
              <span v-if="checkoutType === 'gogo'">GOGO</span>
              Checkout
            </h1>
          </v-flex>
        </v-layout>

        <v-layout justify-space-between row wrap>
          <v-flex
            xs12
            sm6
            :class="$vuetify.breakpoint.xsAndDown ? 'px-2' : 'px-3'"
          >
            <v-card flat>
              <v-card-title
                :class="
                  $vuetify.breakpoint.smAndDown ? 'pt-3 px-3' : 'pt-4 px-4'
                "
                class="pb-0"
              >
                <h2>Your Information</h2>
              </v-card-title>

              <v-card-text
                :class="$vuetify.breakpoint.smAndDown ? 'px-3' : 'px-4'"
              >
                <v-layout row wrap>
                  <v-flex xs12 sm12 md7 class="my-2">
                    <h5>First Name</h5>
                    <h6>{{ quote.customer.firstName }}</h6>
                  </v-flex>
                  <v-flex xs12 sm12 md5 class="my-2">
                    <h5>Last Name</h5>
                    <h6>{{ quote.customer.lastName }}</h6>
                  </v-flex>
                  <v-flex xs12 sm12 md7 class="my-3">
                    <h5>Email</h5>
                    <h6>{{ quote.customer.email }}</h6>
                  </v-flex>
                  <v-flex xs12 sm12 md5 class="my-3">
                    <h5>Phone Number</h5>
                    <h6>
                      {{ quote.customer.phone | phoneFormatFilter }}
                    </h6>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>

            <v-card flat class="mt-5 mb-4 pt-2">
              <v-card-title
                :class="
                  $vuetify.breakpoint.smAndDown ? 'pt-2 px-3' : 'pt-3 px-4'
                "
              >
                <h2>Scope of Service</h2>
              </v-card-title>

              <v-card-text
                class="py-4"
                :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-4'"
              >
                <v-layout row class="pb-4">
                  <v-flex xs4>
                    <h5>Start Date</h5>
                    <h6>
                      {{ quote.contractStartDate | itineraryDateFilter() }}
                    </h6>
                  </v-flex>
                  <v-flex xs4>
                    <h5>End Date</h5>
                    <h6>{{ quote.contractEndDate | itineraryDateFilter() }}</h6>
                  </v-flex>
                  <v-flex xs4>
                    <h5>Billing Frequency</h5>
                    <h6>{{ quote.contractBillingFrequency.label }}</h6>
                  </v-flex>
                </v-layout>
                <h5 style="font-size: 13px; white-space: pre">
                  {{ quote.contractNotes }}
                </h5>
              </v-card-text>
            </v-card>

            <v-card
              v-for="(trip, tripIndex) in trips"
              :key="`checkout-form-trip-${tripIndex}`"
              flat
              class="mt-5 mb-4 pt-2"
            >
              <v-card-title
                :class="
                  $vuetify.breakpoint.smAndDown ? 'pt-2 px-3' : 'pt-3 px-4'
                "
              >
                <v-layout align-start row wrap>
                  <v-flex xs12>
                    <div style="margin-bottom: 7px">
                      <h5>Trip #{{ tripIndex + 1 }}</h5>
                      <h6>{{ trip.routeName }}</h6>
                    </div>
                  </v-flex>
                  <v-flex>
                    <h5>Trip Type</h5>
                    <h6>{{ trip.tripType.label }}</h6>
                  </v-flex>
                  <v-flex>
                    <h5>Vehicles</h5>
                    <h6
                      v-for="(vehicle, vehicleIndex) in trip.vehicles"
                      :key="`trip-${tripIndex}-vehicle-${vehicleIndex}`"
                    >
                      {{ vehicle.quantity }}
                      {{
                        pluralize(vehicle.quantity, vehicle.vehicleType.label)
                      }}
                    </h6>
                  </v-flex>
                  <v-flex>
                    <h5>Start Date</h5>
                    <h6>{{ trip.startDate | itineraryDateFilter }}</h6>
                  </v-flex>
                  <v-flex v-if="trip.hasRecurrence">
                    <h5>End Date</h5>
                    <h6>
                      {{ recurrenceEndDate(trip) | itineraryDateFilter }}
                    </h6>
                  </v-flex>
                  <v-flex v-if="trip.hasRecurrence">
                    <h5>Repeat</h5>
                    <h6>{{ trip.recurrenceTripCount || '1' }}</h6>
                  </v-flex>
                </v-layout>
              </v-card-title>

              <v-divider style="margin: 0" />

              <v-card-text
                class="py-4"
                :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-4'"
              >
                <v-layout row class="pb-4">
                  <v-flex xs2>
                    <h6 class="header-smaller">Stops</h6>
                  </v-flex>
                  <v-flex xs5 md4>
                    <h6 class="header-smaller">Address</h6>
                  </v-flex>

                  <v-flex v-if="$vuetify.breakpoint.mdAndUp" mx3>
                    <h6 class="header-smaller" style="text-align: center">
                      Dropoff
                    </h6>
                  </v-flex>

                  <v-flex v-if="$vuetify.breakpoint.mdAndUp" mx3>
                    <h6 class="header-smaller" style="text-align: center">
                      Pickup
                    </h6>
                  </v-flex>

                  <v-flex v-if="$vuetify.breakpoint.smAndDown" sm5>
                    <h6 class="header-smaller" style="text-align: center">
                      Dropoff / Pickup
                    </h6>
                  </v-flex>
                </v-layout>

                <v-layout
                  v-for="(stop, stopIndex) in trip.stops"
                  :key="`checkout-form-trip-${tripIndex}-stop-${stopIndex}`"
                >
                  <v-flex xs2>
                    <span class="itinerary-number" style="margin-left: 10px">
                      {{ stopIndex + 1 }}
                    </span>
                  </v-flex>

                  <v-flex xs5 md4>
                    <p>
                      <span v-if="stop.address.title">
                        {{ stop.address.title }}
                        <br />
                      </span>
                      <span>{{ stop.address.name }}</span>
                    </p>
                  </v-flex>

                  <v-flex v-if="$vuetify.breakpoint.mdAndUp" md3>
                    <div v-if="stop.dropoffDatetime">
                      <p style="text-align: center">
                        {{
                          stop.dropoffDatetime
                            | itineraryTimeFilter(stop.address.timeZone)
                        }}
                      </p>
                    </div>
                  </v-flex>

                  <v-flex v-if="$vuetify.breakpoint.mdAndUp" md3>
                    <div v-if="stop.pickupDatetime">
                      <p style="text-align: center">
                        {{
                          stop.pickupDatetime
                            | itineraryTimeFilter(stop.address.timeZone)
                        }}
                      </p>
                    </div>
                  </v-flex>

                  <v-flex v-if="$vuetify.breakpoint.smAndDown" xs5>
                    <div v-if="stop.dropoffDatetime">
                      <p style="text-align: center">
                        Dropoff
                        <br />
                        {{
                          stop.dropoffDatetime
                            | itineraryTimeFilter(stop.address.timeZone)
                        }}
                      </p>
                    </div>

                    <div v-if="stop.pickupDatetime">
                      <p style="text-align: center">
                        Pickup
                        <br />
                        {{
                          stop.pickupDatetime
                            | itineraryTimeFilter(stop.address.timeZone)
                        }}
                      </p>
                    </div>
                  </v-flex>
                </v-layout>
              </v-card-text>

              <v-divider style="margin: 0" />

              <v-card-text
                v-if="trip.description"
                class="py-4"
                :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-4'"
              >
                <h5 style="margin-bottom: 6px">Trip Notes</h5>
                <h6 style="white-space: pre-wrap">{{ trip.description }}</h6>
              </v-card-text>

              <v-divider v-if="trip.description" style="margin: 0" />

              <v-card-text
                v-if="trip.tripContact"
                class="py-4"
                :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-4'"
              >
                <h5>Trip Contact</h5>
                <h6 v-if="!trip.tripContact">No trip contact listed.</h6>
                <div v-else>
                  <h6 class="py-1">
                    {{ trip.tripContact && trip.tripContact.firstName }}
                    {{ trip.tripContact && trip.tripContact.lastName }}
                  </h6>
                  <v-layout row>
                    <v-flex pr-1>
                      <a
                        :href="`tel:${
                          trip.tripContact && trip.tripContact.phone
                        }`"
                      >
                        {{
                          trip.tripContact &&
                          trip.tripContact.phone | phoneFormatFilter
                        }}
                      </a>
                    </v-flex>

                    <v-flex
                      v-if="
                        trip.tripContact.email &&
                        !trip.tripContact.email.includes('tripContact')
                      "
                    >
                      <a
                        :href="`mailto:${
                          trip.tripContact && trip.tripContact.email
                        }`"
                      >
                        {{ trip.tripContact && trip.tripContact.email }}
                      </a>
                    </v-flex>
                  </v-layout>
                </div>
              </v-card-text>

              <v-divider v-if="trip.tripContact" style="margin: 0" />

              <v-card-text
                v-if="trip.rates && trip.rates.length"
                class="py-4"
                :class="$vuetify.breakpoint.smAndDown ? 'pa-3' : 'pa-4'"
              >
                <h5 style="margin-bottom: 6px">Overage Rates</h5>
                <div
                  v-for="(rate, rateIndex) in trip.rates"
                  :key="`trip-rates-${rateIndex}`"
                  class="trip-rate"
                >
                  <v-layout row class="py-2">
                    <v-flex grow>
                      <h6 class="trip-rate-label">
                        {{ rate.rateType.label }}
                        <span
                          v-if="trip.hasRecurrence"
                          style="font-weight: 400"
                        >
                          per trip
                        </span>
                      </h6>
                    </v-flex>
                    <v-flex shrink>
                      <h6 class="trip-rate-amount">
                        {{ priceFilter(rate.amount) }}
                      </h6>
                    </v-flex>
                  </v-layout>
                </div>
              </v-card-text>

              <v-divider v-if="trip.description" style="margin: 0" />

              <v-card-text
                :class="
                  $vuetify.breakpoint.smAndDown ? 'py-2 px-3' : 'py-3 px-4'
                "
              >
                <div
                  v-for="(charge, chargeIndex) in trip.charges"
                  :key="`trip-charges-${chargeIndex}`"
                  class="trip-charge"
                >
                  <v-layout row class="py-2">
                    <v-flex grow>
                      <h6 class="trip-charge-label">
                        {{ charge.chargeType.label }}
                        <span
                          v-if="trip.hasRecurrence"
                          style="font-weight: 400"
                        >
                          per trip
                        </span>
                      </h6>
                    </v-flex>
                    <v-flex shrink>
                      <h6 class="trip-charge-amount">
                        {{ priceFilter(charge.amount) }}
                      </h6>
                    </v-flex>
                  </v-layout>
                </div>
              </v-card-text>

              <v-card-text
                :style="`background-color:${headerColor}`"
                :class="
                  $vuetify.breakpoint.smAndDown ? 'py-2 px-3' : 'py-3 px-4'
                "
              >
                <v-layout row wrap class="py-2">
                  <v-flex grow pb-1>
                    <h3 class="header-heavy">
                      Total
                      <span v-if="trip.hasRecurrence">per Trip</span>
                    </h3>
                  </v-flex>

                  <v-flex shrink pb-1>
                    <h3 class="header-heavy">
                      {{ priceFilter(trip.total) }}
                    </h3>
                  </v-flex>
                </v-layout>
              </v-card-text>
              <v-card-text
                v-if="
                  trip.paymentType && trip.paymentType.key === 'down_payment'
                "
                :class="
                  $vuetify.breakpoint.smAndDown ? 'py-2 px-3' : 'py-3 px-4'
                "
              >
                <v-layout row class="py-2">
                  <v-flex xs6>
                    <h5>Down Payment</h5>
                    <h6>
                      {{ priceFilter(getAmountDueTodayForTrip(trip)) }}
                      <br />
                      {{ parseInt(trip.depositPercentage, 10) }}% Due Today
                    </h6>
                  </v-flex>

                  <v-flex
                    xs6
                    pl-4
                    :style="`border-left: 1px solid ${$cr.theme.borderGray};`"
                  >
                    <h5>Remaining Balance</h5>
                    <h6>
                      {{ priceFilter(getAmountDueLaterForTrip(trip)) }}
                      <br />
                      Due
                      {{
                        dateAsShortString(
                          trip.dueDate,
                          quote.company.address.timeZone
                        )
                      }}
                    </h6>
                  </v-flex>
                </v-layout>
              </v-card-text>
            </v-card>

            <div
              v-if="$vuetify.breakpoint.smAndUp"
              class="company-reviews company-reviews-desktop"
            >
              <template v-if="checkoutType === 'gogo'">
                <div class="mt-3">
                  <v-layout row justify-space-between>
                    <v-flex
                      v-for="(rs, rsIndex) in reviews"
                      :key="rsIndex"
                      class="review-site"
                      xs3
                    >
                      <v-layout column class="body">
                        <img :class="`logo logo__${rs.key}`" :src="rs.logo" />
                        <StarRating
                          :rating="5"
                          read-only
                          dense
                          :show-rating="false"
                          :max-rating="5"
                          :star-size="15"
                        />
                        <div>{{ rs.count }} Reviews</div>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </div>
              </template>
              <template v-else>
                <div
                  v-for="(review, i) in reviews"
                  :key="i"
                  class="py-3 px-1"
                  style="text-align: center"
                >
                  <v-rating
                    :color="checkoutType === 'shofur' ? '#dfb200' : '#008aff'"
                    readonly
                    :value="5"
                    :size="19"
                    dense
                    class="pb-2"
                  />
                  {{ review.text }}
                  <br />
                  <br />
                  - {{ review.reviewer }}
                </div>
              </template>
            </div>
          </v-flex>

          <v-flex
            xs12
            sm6
            :class="$vuetify.breakpoint.xsAndDown ? 'px-2' : 'px-3'"
          >
            <v-card>
              <v-card-text
                :class="
                  $vuetify.breakpoint.smAndDown ? 'pt-3 px-3' : 'pt-4 px-4'
                "
              >
                <v-layout
                  class="grand-total pa-3"
                  align-center
                  :style="`background-color:${headerColor2}`"
                >
                  <v-flex grow>
                    <h3>Total Cost</h3>
                  </v-flex>
                  <v-flex shrink>
                    <h3>{{ priceFilter(contractTotalAmount) }}</h3>
                  </v-flex>
                </v-layout>

                <div v-if="!allTripsFullPayment">
                  <div
                    v-for="(trip, tripIndex) in trips"
                    :key="`checkout-provider-payment-summary-trip-${
                      tripIndex + 1
                    }`"
                  >
                    <v-card-title class="mx-1">
                      <v-layout align-center>
                        <v-flex grow>
                          <div>
                            <span>
                              Trip #{{ tripIndex + 1 }} ({{
                                trip.recurrenceTripCount
                              }}
                              days)
                            </span>
                          </div>
                        </v-flex>
                        <v-flex shrink>
                          <div>
                            {{ priceFilter(trip.recurringTripTotal) }}
                          </div>
                        </v-flex>
                      </v-layout>
                    </v-card-title>
                  </div>

                  <v-layout
                    class="amount-due-today pa-3"
                    align-center
                    :style="`background-color:${headerColor3}`"
                  >
                    <v-flex grow>
                      <h3>Amount Due Today</h3>
                    </v-flex>
                    <v-flex shrink>
                      <h3>
                        {{ priceFilter(amountDueToday) }}
                      </h3>
                    </v-flex>
                  </v-layout>
                </div>
              </v-card-text>
            </v-card>

            <v-card flat class="mt-5 mb-3">
              <v-card-text
                :class="
                  $vuetify.breakpoint.smAndDown ? 'pt-3 px-3' : 'pt-4 px-4'
                "
              >
                <h4>Terms &amp; Conditions for Transportation Reservations</h4>

                <br />

                <div
                  style="
                    height: 200px;
                    font-size: 11px;
                    overflow: scroll;
                    white-space: pre-wrap;
                  "
                >
                  {{ terms }}
                </div>
              </v-card-text>
            </v-card>

            <v-form ref="accept-checkbox">
              <v-checkbox
                id="checkout-checkbox-agree-to-terms"
                v-model="agree"
                :rules="[(v) => !!v || 'You must agree to continue.']"
                style="justify-content: center"
              >
                <template #label>
                  <span style="font-size: 13px">
                    I agree to the Terms and Conditions
                  </span>
                </template>
              </v-checkbox>
            </v-form>

            <v-layout column align-center>
              <v-flex style="width: 100%; max-width: 350px">
                <v-btn
                  id="checkout-button-reserve-now"
                  type="submit"
                  class="broker-form-button-submit"
                  :color="buttonColor"
                  :loading="isSubmitting"
                  style="color: white; width: 100%; height: 56px"
                  tabindex="8"
                  block
                  large
                >
                  <span style="font-size: 22px">Agree to Terms</span>
                  <template #loader>
                    <v-progress-circular indeterminate color="white" />
                  </template>
                </v-btn>
              </v-flex>
            </v-layout>

            <v-layout row justify-center style="margin-top: 50px">
              <v-flex :class="$vuetify.breakpoint.smAndUp ? 'xs8' : 'xs12'">
                <template v-if="checkoutType !== 'national'">
                  <v-layout row justify-space-between>
                    <v-flex class="icon-display" xs4>
                      <v-icon size="40" class="mb-4">
                        {{
                          checkoutType === 'gogo'
                            ? '$vuetify.icons.gogoSupport'
                            : '$vuetify.icons.shofurHelp'
                        }}
                      </v-icon>
                      <div>
                        {{ checkoutType === 'gogo' ? '24/7' : 'On Demand' }}
                      </div>
                      <div>
                        {{ checkoutType === 'gogo' ? 'Support' : 'Help Desk' }}
                      </div>
                    </v-flex>

                    <v-flex class="icon-display" xs4>
                      <v-icon size="40" class="mb-4">
                        {{
                          checkoutType === 'gogo'
                            ? '$vuetify.icons.gogoSafe'
                            : '$vuetify.icons.shofurInsurance'
                        }}
                      </v-icon>
                      <div>
                        {{ checkoutType === 'gogo' ? 'Reliable' : '$5M' }}
                      </div>
                      <div>
                        {{ checkoutType === 'gogo' ? 'Service' : 'Insurance' }}
                      </div>
                    </v-flex>

                    <v-flex class="icon-display" xs4>
                      <v-icon size="40" class="mb-4">
                        {{
                          checkoutType === 'gogo'
                            ? '$vuetify.icons.gogoReliable'
                            : '$vuetify.icons.shofurSecure'
                        }}
                      </v-icon>
                      <div>
                        {{
                          checkoutType === 'gogo'
                            ? 'Safe & Secure'
                            : 'Secure Online'
                        }}
                      </div>
                      <div>Checkout</div>
                    </v-flex>
                  </v-layout>
                </template>
                <template v-else>
                  <v-layout row justify-center>
                    <img src="@/assets/images/secure-auth-net-national.gif" />
                  </v-layout>
                </template>

                <div
                  v-if="$vuetify.breakpoint.xs"
                  class="company-reviews company-reviews-desktop"
                >
                  <template v-if="checkoutType === 'gogo'">
                    <div class="mt-3">
                      <v-layout row wrap justify-space-between>
                        <v-flex
                          v-for="(rs, rsIndex) in reviews"
                          :key="rsIndex"
                          class="review-site"
                          xs12
                          md4
                        >
                          <v-layout column class="body">
                            <img
                              :class="`logo logo__${rs.key}`"
                              :src="rs.logo"
                            />
                            <StarRating
                              :rating="5"
                              read-only
                              dense
                              :show-rating="false"
                              :max-rating="5"
                              :star-size="15"
                            />
                            <div>{{ rs.count }} Reviews</div>
                          </v-layout>
                        </v-flex>
                      </v-layout>
                    </div>
                  </template>

                  <template v-else>
                    <div
                      v-for="(review, i) in reviews"
                      :key="i"
                      class="py-3 px-1"
                      style="text-align: center"
                    >
                      <v-rating
                        :color="
                          checkoutType === 'shofur' ? '#dfb200' : '#008aff'
                        "
                        readonly
                        dense
                        :value="5"
                      />
                      {{ review.text }}
                      <br />
                      <br />
                      - {{ review.reviewer }}
                    </div>
                  </template>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-container>

      <br />
      <br />

      <component :is="checkoutStyle.footer" v-if="checkoutStyle" />
    </v-form>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'

import { DateTime } from 'luxon'
import { currencyFilter, pluralize } from '@/utils/string'
import { phoneFormatFilter } from '@/utils/phone'
import StarRating from 'vue-star-rating'

import 'typeface-barlow'
import 'typeface-montserrat'
import 'typeface-poppins'
import 'typeface-fugaz-one'

import ShofurFooter from '@/components/ShofurFooter.vue'
import GoGoFooter from '@/components/GoGoFooter.vue'
import NationalFooter from '@/components/NationalFooter.vue'
import ShofurHeader from '@/components/ShofurHeader.vue'
import NationalHeader from '@/components/NationalHeader.vue'
import GoGoHeader from '@/components/GoGoHeader.vue'

import trustPilot from '@/assets/images/logo-trustpilot.svg'
import reseller from '@/assets/images/logo-reseller-ratings.png'
import googleLogo from '@/assets/images/logo-google.png'

import CheckoutMixin from '@/components/CheckoutMixin'

const faviconMap = {
  national: 'https://www.nationalbuscharter.com/images/national_favicon.png',
  gogo: 'https://gogocharters.com/img/gogo_favicon.png',
  shofur: 'https://shofur.com/images/favicon.png',
}

export default {
  directives: {
    mask,
  },
  components: {
    ShofurHeader,
    ShofurFooter,
    NationalHeader,
    NationalFooter,
    GoGoHeader,
    GoGoFooter,
    StarRating,
  },
  filters: {
    phoneFormatFilter,
    itineraryDateFilter(datetime, zone) {
      return DateTime.fromISO(datetime, { zone })
        .toLocaleString(DateTime.DATETIME_SHORT)
        .split(',')?.[0]
    },
    itineraryTimeFilter(datetime, zone) {
      return DateTime.fromISO(datetime, { zone })
        .toLocaleString(DateTime.DATETIME_SHORT)
        .split(',')?.[1]
    },
  },
  mixins: [CheckoutMixin],
  metaInfo() {
    if (faviconMap?.[this.checkoutType]) {
      this.changeFavicon(faviconMap[this.checkoutType])
    }

    return {
      title:
        'Checkout' +
        (this.checkoutStyles[this.checkoutType]?.metaTitle
          ? ' - ' + this.checkoutStyles[this.checkoutType]?.metaTitle
          : ''),
    }
  },
  props: {
    quote: {
      type: Object,
      default: () => ({
        company: {
          address: {},
        },
        customer: {},
        notes: [],
      }),
    },
    terms: {
      type: String,
      default: '',
    },
    isSubmitting: {
      type: Boolean,
    },
    currencyType: {
      type: String,
      default: '',
    },
    exchangeRate: {
      type: Number,
      default: null,
    },
    contractTotalAmount: {
      type: Number,
      default: null,
    },
    trips: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formIsValid: false,
      billingAddressFormIsValid: false,
      activePaymentMethod: '',
      availablePaymentMethods: ['credit_card', 'check'],
      providerId: null,
      agree: false,
      firstName: {
        value: '',
        rules: [(v) => !!v || 'First name is required'],
      },
      lastName: {
        value: '',
        rules: [(v) => !!v || 'Last name is required'],
      },
      email: {
        value: '',
        rules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
      },
      phone: {
        value: '',
        mask: '+1 (###) ###-####',
        rules: [(v) => !!v || 'Phone number is required'],
      },
      cardName: {
        value: '',
        rules: [(v) => !!v || 'Name is required'],
      },
      cardNumber: {
        value: '',
        errorMessages: null,
        rules: [
          (v) => !!v || 'Card number is required',
          (v) => v.length >= 15 || 'Card number is not valid',
        ],
      },
      cardExpirationMonth: {
        value: '01',
        rules: [(v) => !!v || 'Card expiration month is required'],
        errorMessages: null,
        options: (() => {
          const months = []
          for (let i = 1; i <= 12; i++) {
            if (i < 10) {
              months.push('0' + i.toString())
            } else {
              months.push(i.toString())
            }
          }
          return months
        })(),
      },
      cardExpirationYear: {
        value: DateTime.local().year.toString(),
        rules: [(v) => !!v || 'Card expiration year is required'],
        errorMessages: null,
        options: (() => {
          const currentYear = DateTime.local().year
          const years = []
          for (let i = 0; i < 10; i++) {
            years.push((currentYear + i).toString())
          }
          return years
        })(),
      },
      cardCVV: {
        value: '',
        masks: {
          default: '###',
          visa: '###',
          mastercard: '###',
          discover: '###',
          amex: '####',
          diners: '###',
        },
        errorMessages: null,
        rules: [
          (v) => !!v || 'Card CVV is required',
          (v) => v.length >= 3 || 'Card CVV is not valid',
        ],
      },
      paymentServiceError: '',
      address1: {
        value: '',
        rules: [(v) => !!v || 'Address is required'],
      },
      zip: {
        value: '',
        rules: [
          (v) => !!v || 'Zip code is required',
          (v) => (v.length >= 5 && v.length <= 10) || 'Zip code is not valid',
        ],
      },
      city: {
        value: '',
        rules: [(v) => !!v || 'City is required'],
      },
      checkoutStyles: {
        shofur: {
          backgroundColor: '#f4f4f4',
          headerColor: '#e2e2e2',
          headerColor2: '#e2e2e2',
          headerColor3: '#696969',
          buttonColor: '#8ec63d',
          fontFamily: 'Montserrat',
          metaTitle: 'Shofur LLC',
          reviews: [
            {
              reviewer: ' Scott Snow, Spirit Airlines',
              text:
                'Thank you so much for being able to get us so many buses last minute!',
            },
            {
              reviewer: 'Ginelle Charles, NFL',
              text:
                'I truly appreciate all the attention to detail and the patience that you had with us.',
            },
            {
              reviewer: 'Kizo M.',
              text:
                'Enjoyable ride through the city of Atlanta. The bus was new, comfortable, cool and clean. The staff are friendly and helpful.',
            },
          ],
          header: ShofurHeader,
          footer: ShofurFooter,
        },
        gogo: {
          fontFamily: 'Poppins',
          backgroundColor: '#f2f6f9',
          headerColor: '#e4edf4',
          headerColor2: '#42516d',
          headerColor3: this.$cr.theme.gogo,
          buttonColor: '#fd6a22',
          metaTitle: 'GOGO Charters LLC',
          reviews: [
            {
              logo: trustPilot,
              count: 205,
              key: 'trustpilot',
            },
            {
              logo: reseller,
              count: 189,
              key: 'reseller',
            },
            {
              logo: googleLogo,
              count: 596,
              key: 'google',
            },
          ],
          header: GoGoHeader,
          footer: GoGoFooter,
        },
        national: {
          fontFamily: 'Barlow',
          backgroundColor: '#f2f6f9',
          headerFontFamily: 'Fugaz One',
          headerColor: '#e4edf4',
          headerColor2: '#7485a3',
          headerColor3: '#42516d',
          buttonColor: '#186db5',
          metaTitle: 'National Bus Charter',
          reviews: [
            {
              reviewer: 'Ben R.',
              text:
                'I booked a trip from Los Angeles to San Diego this past weekend. The quote process was quick and painless and everything went smoothly.',
            },
            {
              reviewer: 'Adam L.',
              text:
                'The bus tracking through the app is helpful when locating the buses we have reserved. We are also able to contact the driver directly through the app during the trip to provide specific directions or requests.',
            },
            {
              reviewer: 'Joe D.',
              text:
                'I was able to find pricing right in my budget and still being provided a quality bus! I saved so much time comparing all the prices right in front of me on my computer!',
            },
          ],
          header: NationalHeader,
          footer: NationalFooter,
        },
      },
    }
  },
  computed: {
    header() {
      return this.checkoutStyle?.header
    },
    checkoutType() {
      return this.checkoutData?.key
    },
    checkoutStyle() {
      return this.checkoutStyles[this.checkoutType]
    },
    reviews() {
      return this.checkoutStyle?.reviews
    },
    buttonColor() {
      return this.checkoutStyle?.buttonColor
    },
    backgroundColor() {
      return this.checkoutStyle?.backgroundColor
    },
    headerColor() {
      return this.checkoutStyle?.headerColor
    },
    headerColor2() {
      return this.checkoutStyle?.headerColor2
    },
    headerColor3() {
      return this.checkoutStyle?.headerColor3
    },
    fontFamily() {
      return this.checkoutStyle?.fontFamily
    },
    checkoutData() {
      return this.quote?.checkoutPage
    },
    cardNumberMask() {
      if (this.cardType === 'amex') {
        return '#### ###### #####'
      }
      if (this.cardType === 'diners') {
        return '#### ###### ####'
      }
      return '#### #### #### ####'
    },
    cardType() {
      if (this.cardNumber?.value?.[0] === '5') {
        return 'mastercard'
      } else if (this.cardNumber?.value?.[0] === '4') {
        return 'visa'
      } else if (this.cardNumber?.value?.[0] === '6') {
        return 'discover'
      } else if (
        ['34', '37'].includes(this.cardNumber?.value?.substring(0, 2))
      ) {
        return 'amex'
      } else if (
        ['30', '36', '38'].includes(this.cardNumber?.value?.substring(0, 2))
      ) {
        return 'diners'
      } else {
        return 'default'
      }
    },
    cardNumberRawValue() {
      return this.cardNumber.value.split(' ').join('')
    },
    startDate() {
      const then = DateTime.fromISO(this.trips?.[0]?.startDate).toLocaleString({
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      })
      return then || ''
    },
    allTripsFullPayment() {
      return (
        this.trips?.filter((trip) => trip.paymentType?.key !== 'full_payment')
          ?.length === 0
      )
    },
    onlyOneTrip() {
      return this.trips?.length === 1
    },
  },
  created() {
    if (this.paymentMethodAllowed('credit_card')) {
      this.activePaymentMethod = 'credit_card'
    } else if (this.paymentMethodAllowed('check')) {
      this.activePaymentMethod = 'check'
    }
  },
  methods: {
    phoneFormatFilter,
    priceFilter(amount) {
      if (this.currencyType === 'USD') {
        return currencyFilter(amount)
      }
      return `${currencyFilter(
        amount * this.exchangeRate,
        this.currencyType
      )} ${this.currencyType} (${currencyFilter(amount)} USD)`
    },
    pluralize,
    paymentMethodAllowed(type) {
      return this.trips[0]?.paymentMethods?.find((method) => {
        return method.isAllowed && method.paymentMethodType?.key === type
      })
    },
    changeFavicon(src) {
      const head = document.head || document.getElementsByTagName('head')[0]
      const link = document.createElement('link')
      const oldLink = document.getElementById('dynamic-favicon')
      link.id = 'dynamic-favicon'
      link.rel = 'shortcut icon'
      link.href = src
      if (oldLink) {
        head.removeChild(oldLink)
      }
      head.appendChild(link)
    },
    clearErrorMessages() {
      this.cardNumber.errorMessages = null
      this.cardExpirationMonth.errorMessages = null
      this.cardExpirationYear.errorMessages = null
      this.paymentServiceError = ''
    },
    submit() {
      const userCheckedAgree = this.$refs['accept-checkbox'].validate()

      if (!userCheckedAgree) {
        return
      }

      this.clearErrorMessages()
      this.$emit('submit')
    },
    tripPaymentTypeText(trip) {
      if (trip?.paymentType?.key === 'down_payment') {
        return `Deposit (${parseInt(trip.depositPercentage, 10)}%)`
      }
      return trip?.paymentType?.label
    },
    recurrenceEndDate(trip) {
      return trip?.recurrences?.[0]?.endDate || trip.startDate
    },
  },
}
</script>

<style lang="scss" scoped>
.checkout-broker {
  h1 {
    font-size: 40px;
    font-weight: normal;
    letter-spacing: normal;
  }

  h2 {
    font-size: 22px;
    border-bottom: none;
  }

  h3 {
    font-size: 20px;

    &.header-heavy {
      font-weight: 500;
    }
  }

  h4 {
    font-size: 16px;
    margin: 0;
    line-height: 1.5;
    font-weight: normal;
  }

  h5,
  h6 {
    font-size: 14px;
    line-height: 1.5;
  }

  h6 {
    margin-top: 0;

    &.header-smaller {
      font-size: 11px;
    }
  }

  a {
    text-decoration: none;
  }

  .grand-total {
    h3 {
      color: $white;
      font-weight: 500;
    }
  }

  .amount-due-today {
    h3 {
      color: $white;
      font-weight: bold;
    }
  }

  .v-btn-toggle {
    > .v-btn {
      color: $gray;
      background: none !important;
      text-transform: uppercase !important;
      border-radius: 0 !important;
      border-bottom: 2px solid $white;
      padding-left: 28px;
      padding-right: 28px;

      &::before {
        opacity: 0 !important;
      }
    }
  }

  .select-native-wrapper {
    position: relative;

    .v-icon {
      position: absolute;
      right: 4px;
      top: 22px;
    }
  }

  .select-native {
    border: 1px solid gray;
    border-radius: 5px;
    height: 56px;
    width: 100%;
    margin-top: 5px;
    padding: 10px;

    &:focus {
      outline: none;
    }
  }

  .card-expiration-month-native {
  }

  &-shofur {
    div,
    p {
      color: $shofur-text;
    }

    a {
      color: $shofur;
    }

    h2 {
      color: $shofur-text-lighter;
      font-weight: bold;
      letter-spacing: 0.44px;
    }

    h5 {
      color: $shofur-text-light;
    }

    .grand- {
      h3 {
        color: $shofur-black;
      }
    }

    .select-native:focus {
      border-color: $shofur;
    }

    .v-input--is-focused {
      color: $shofur !important;
      caret-color: $shofur !important;
    }

    .v-btn-toggle > .v-btn--active {
      border-bottom: 2px solid $shofur;
    }
  }

  &-gogo {
    div,
    p {
      color: $gogo-text;
    }

    a {
      color: $gogo;
    }

    h1 {
      text-transform: uppercase;
      color: $gogo-dark;
      font-weight: bold;
    }

    h2 {
      color: $gogo-dark;
      font-weight: 600;
      letter-spacing: 0.44px;
    }

    h6 {
      color: $gogo-dark;
    }

    .select-native:focus {
      border-color: $gogo;
    }

    .v-input--is-focused {
      color: $gogo !important;
      caret-color: $gogo !important;
    }

    .v-btn-toggle > .v-btn--active {
      border-bottom: 2px solid $gogo;
    }

    .broker-form-button-submit {
      border-radius: 28px;
      font-size: 18px;
      background-image: linear-gradient(to top, #fd4022, #fd6a22);
    }
  }

  &-national {
    div,
    p {
      color: $national-text;
    }

    a {
      color: $national;
    }

    h1 {
      text-transform: uppercase;
      color: $national;
    }

    h3 {
      &.header-heavy {
        font-weight: 600;
      }
    }

    .grand-total {
      h3 {
        font-weight: normal;
      }
    }

    .select-native:focus {
      border-color: $national;
    }

    .v-input--is-focused {
      color: $national !important;
      caret-color: $national !important;
    }

    .v-btn-toggle > .v-btn--active {
      border-bottom: 2px solid $national;
    }
  }

  ::v-deep {
    .v-select.v-text-field--enclosed:not(.v-text-field--single-line)
      .v-select__selections {
      padding-top: 0px;
    }

    .v-input--selection-controls:not(.v-input--hide-details) .v-input__slot {
      margin-bottom: 0;
    }

    .v-text-field--box input,
    .v-text-field--full-width input,
    .v-text-field--outline input {
      margin-top: 11px;
    }

    .v-text-field.v-text-field--enclosed .v-input__append-inner {
      margin-top: 8px;
    }

    .v-text-field.v-text-field--enclosed
      .v-select__slot
      .v-input__append-inner {
      margin-top: 14px;
    }
  }
}

.icon-display {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: 600;
  svg {
    color: $gray-mid-light;
  }
}

.coachrail .theme--light.v-select ::v-deep .v-select__selections {
  color: $black !important;
}

.coachrail {
  .checkout-broker-shofur {
    .application {
      font-family: 'Montserrat', sans-serif;
    }

    html {
      font-family: 'Montserrat', sans-serif;
    }

    div,
    span,
    li,
    ul,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    label,
    body,
    button,
    input,
    select,
    textarea {
      font-family: 'Montserrat', sans-serif;
    }
  }

  .checkout-broker-gogo {
    .application {
      font-family: 'Poppins', sans-serif;
    }

    html {
      font-family: 'Poppins', sans-serif;
    }

    div,
    span,
    li,
    ul,
    p,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    label,
    body,
    button,
    input,
    select,
    textarea {
      font-family: 'Poppins', sans-serif;
    }
  }

  .checkout-broker-national {
    .application {
      font-family: 'Barlow', sans-serif;
    }

    html {
      font-family: 'Barlow', sans-serif;
    }

    div,
    span,
    li,
    ul,
    p,
    h1,
    h3,
    h4,
    h5,
    h6,
    label,
    body,
    button,
    input,
    select,
    textarea {
      font-family: 'Barlow', sans-serif;
    }

    h2 {
      font-family: 'Fugaz One', sans-serif;
      color: $national-light;
    }
  }
}

.review-site {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;

  .body {
    align-items: center;
  }

  .logo {
    width: 120px;
    height: 30px;
    margin-bottom: 10px;

    &__reseller {
      height: 25px;
      position: relative;
      top: 5px;
      margin-bottom: 15px;
    }

    &__google {
      max-width: 145px;
      height: 40px;
      margin-bottom: 0;
    }
  }
}
</style>
