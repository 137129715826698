<template>
  <v-card>
    <v-card-title>
      <h2>Summary</h2>
    </v-card-title>
    <v-card-text>
      <v-layout wrap dense style="margin-bottom: 18px; font-size: 16px">
        <template v-if="total !== amountDueToday">
          <v-flex xs8 class="gray-lighter">
            <h2 style="font-size: 16px">Grand Total</h2>
          </v-flex>
          <v-spacer />
          <v-flex xs4 class="gray-lighter">
            <h2 style="font-size: 16px">
              {{ priceFilter(contractTotalAmount) }}
            </h2>
          </v-flex>
          <v-spacer />
        </template>

        <template v-for="(trip, tripIndex) in trips">
          <v-flex
            :key="`checkout-charterup-payment-trip-${tripIndex + 1}-label`"
            xs8
          >
            <span v-if="trip.routeName">{{ trip.routeName }}</span>
            <span v-else>Trip #{{ tripIndex + 1 }}</span>
            <span v-if="trip.hasRecurrence">
              ({{ trip.recurrenceTripCount }} days)
            </span>
          </v-flex>

          <v-spacer
            :key="`checkout-charterup-payment-trip-${tripIndex + 1}-spacer`"
          />
          <v-flex
            :key="`checkout-charterup-payment-trip-${tripIndex + 1}-value`"
            xs4
          >
            <span>
              {{ priceFilter(trip.recurringTripTotal) }}
            </span>
          </v-flex>
        </template>

        <v-flex xs8>
          <h2 v-if="total === amountDueToday" style="font-size: 16px">
            Grand Total
          </h2>
          <h2 v-else style="font-size: 16px">Amount Due Today</h2>
        </v-flex>
        <v-spacer />
        <v-flex xs4>
          <h2 style="font-size: 16px">
            {{ priceFilter(contractTotalAmount) }}
          </h2>
        </v-flex>
      </v-layout>
    </v-card-text>
  </v-card>
</template>

<script>
import { currencyFilter } from '@/utils/string'
import CheckoutMixin from '@/components/CheckoutMixin'

export default {
  name: 'ContractBillingSummary',
  mixins: [CheckoutMixin],
  props: {
    trips: {
      type: Array,
      default: () => [],
    },
    currencyType: {
      type: String,
      default: '',
    },
    exchangeRate: {
      type: Number,
      default: null,
    },
    contractTotalAmount: {
      type: Number,
      default: null,
    },
  },
  methods: {
    priceFilter(amount) {
      if (this.currencyType === 'USD') {
        return currencyFilter(amount)
      }
      return `${currencyFilter(
        amount * this.exchangeRate,
        this.currencyType
      )} ${this.currencyType} (${currencyFilter(amount)} USD)`
    },
    currencyFilter,
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/charterup/design';

.primary-text {
  font-size: 18px;
  color: $primary;
}

.gray-lighter h2 {
  color: $gray-light !important;
}
</style>
