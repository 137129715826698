<template>
  <v-form
    ref="form"
    v-model="formIsValid"
    class="charterup"
    @submit.prevent="submit"
  >
    <CharterUpToolbar>
      Contract ID: {{ quote.quoteId }}
    </CharterUpToolbar>

    <TwoColumnLayout
      v-if="!quoteDetailLoading && quote"
      :left-column="6"
      max-width="1100px"
    >
      <template #left-column>
        <v-card>
          <v-card-title>
            <h2>Your Information</h2>
          </v-card-title>

          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12 sm12 md7 class="my-2">
                <h5 style="font-size: 16px">First Name</h5>
                <p style="font-size: 16px; margin-top: 0">
                  {{ quote.customer.firstName }}
                </p>
              </v-flex>
              <v-flex xs12 sm12 md5 class="my-2">
                <h5 style="font-size: 16px">Last Name</h5>
                <p style="font-size: 16px; margin-top: 0">
                  {{ quote.customer.lastName }}
                </p>
              </v-flex>
              <v-flex xs12 sm12 md7 class="my-3">
                <h5 style="font-size: 16px">Email</h5>
                <p style="font-size: 16px; margin-top: 0">
                  {{ quote.customer.email }}
                </p>
              </v-flex>
              <v-flex xs12 sm12 md5 class="my-3">
                <h5 style="font-size: 16px">Phone Number</h5>
                <p style="font-size: 16px; margin-top: 0">
                  {{ quote.customer.phone | phoneFormatFilter }}
                </p>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title>
            <h2>Terms &amp; Conditions</h2>
          </v-card-title>

          <v-card-text>
            <h4>Terms &amp; Conditions for Transportation Reservations</h4>

            <br />

            <!-- prettier-ignore -->
            <div
              style="
                height: 200px;
                font-size: 11px;
                overflow: scroll;
                white-space: pre-wrap;
                line-height: 1em;
              "
            >{{ terms }}</div>
          </v-card-text>
        </v-card>

        <div v-if="quote && !$vuetify.breakpoint.mdAndUp">
          <v-card>
            <v-card-title>
              <h2>Scope of Service</h2>
            </v-card-title>

            <v-card-text>
              <v-layout row class="pb-4">
                <v-flex xs4>
                  <h5 style="font-size: 16px">Start Date</h5>
                  <p style="font-size: 16px; margin-top: 0">
                    {{ quote.contractStartDate | itineraryDateFilter() }}
                  </p>
                </v-flex>
                <v-flex xs4>
                  <h5 style="font-size: 16px">End Date</h5>
                  <p style="font-size: 16px; margin-top: 0">
                    {{ quote.contractEndDate | itineraryDateFilter() }}
                  </p>
                </v-flex>
                <v-flex xs4>
                  <h5 style="font-size: 16px">Billing Frequency</h5>
                  <p style="font-size: 16px; margin-top: 0">
                    {{ quote.contractBillingFrequency.label }}
                  </p>
                </v-flex>
              </v-layout>
              <!-- prettier-ignore -->
              <h5
                style="
                  font-size: 13px;
                  white-space: pre-wrap;
                  line-height: 1.5em"
              >{{ quote.contractNotes }}</h5>
            </v-card-text>
          </v-card>

          <TripCollectionCard
            style="margin-bottom: 12px; background-color: white"
            :trips="trips"
            :created-on="quote.createdOn"
            itinerary
          />

          <ContractBillingSummary
            :quote="quote"
            :trips="trips"
            :currency-type="currencyType"
            :exchange-rate="exchangeRate"
            :contract-total-amount="contractTotalAmount"
            :cancellation-policy="showCancellationPolicy"
          />
        </div>

        <AlertBox
          :active="!!paymentServiceError"
          type="error"
          style="margin-top: 12px"
        >
          {{ paymentServiceError }}
        </AlertBox>

        <v-layout
          row
          justify="center"
          style="margin-top: 24px; text-align: center"
        >
          <v-flex
            class="justify-center"
            :style="$vuetify.breakpoint.mdAndUp ? {} : { 'font-size': '11px' }"
          >
            <v-form ref="accept-checkbox">
              <v-checkbox
                id="checkout-checkbox-agree-to-terms"
                v-model="agree"
                :rules="[(v) => !!v || 'You must agree to continue.']"
                style="justify-content: center"
              >
                <template #label>
                  <span style="font-size: 13px">
                    I agree to the Terms and Conditions
                  </span>
                </template>
              </v-checkbox>
            </v-form>

            <v-flex class="d-flex justify-center">
              <v-btn
                id="checkout-button-reserve-now"
                type="submit"
                class="primary button-submit"
                :disabled="isSubmitting"
                :loading="isSubmitting"
                style="margin-top: 8px; margin-bottom: 16px"
                block
                large
              >
                <span style="font-size: 22px">Agree to Terms</span>
                <template #loader>
                  <v-progress-circular indeterminate color="white" />
                </template>
              </v-btn>
            </v-flex>
          </v-flex>
        </v-layout>
        <br />
        <v-layout row wrap style="font-size: 14px; text-align: center">
          <v-flex xs12 sm4 mb-4>
            <div style="min-height: 40px; margin-bottom: 10px">
              <v-icon style="width: 35px; height: 35px">
                $vuetify.icons.color-phone
              </v-icon>
            </div>
            <h2 style="font-size: 16px">24/7 Support</h2>
            <p>Award winning customer support is here for you.</p>
          </v-flex>

          <v-flex xs12 sm4 mb-4>
            <div style="min-height: 40px; margin-bottom: 10px">
              <v-icon style="width: 40px; height: 40px">
                $vuetify.icons.color-marker
              </v-icon>
            </div>
            <h2 style="font-size: 16px">Live Tracking</h2>
            <p>Real-time bus tracking the day of your trip.</p>
          </v-flex>

          <v-flex xs12 sm4 mb-4>
            <div style="min-height: 40px; margin-bottom: 10px">
              <v-icon style="width: 38px; height: 38px">
                $vuetify.icons.color-progress
              </v-icon>
            </div>
            <h2 style="font-size: 16px">Unbeatable Prices</h2>
            <p>Largest marketplace for charter buses in the U.S.</p>
          </v-flex>
        </v-layout>
      </template>

      <template #right-column>
        <div v-if="$vuetify.breakpoint.mdAndUp">
          <v-card>
            <v-card-title>
              <h2>Scope of Service</h2>
            </v-card-title>

            <v-card-text>
              <v-layout row class="pb-4">
                <v-flex xs4>
                  <h5 style="font-size: 16px">Start Date</h5>
                  <p style="font-size: 16px; margin-top: 0">
                    {{ quote.contractStartDate | itineraryDateFilter() }}
                  </p>
                </v-flex>
                <v-flex xs4>
                  <h5 style="font-size: 16px">End Date</h5>
                  <p style="font-size: 16px; margin-top: 0">
                    {{ quote.contractEndDate | itineraryDateFilter() }}
                  </p>
                </v-flex>
                <v-flex xs4>
                  <h5 style="font-size: 16px">Billing Frequency</h5>
                  <p style="font-size: 16px; margin-top: 0">
                    {{ quote.contractBillingFrequency.label }}
                  </p>
                </v-flex>
              </v-layout>
              <!-- prettier-ignore -->
              <h5
                style="
                  font-size: 13px;
                  white-space: pre-wrap;
                  line-height: 1.5em"
              >{{ quote.contractNotes }}</h5>
            </v-card-text>
          </v-card>

          <TripCollectionCard
            style="margin-bottom: 24px; background-color: white"
            :trips="trips"
            :created-on="quote.createdOn"
            itinerary
          />

          <ContractBillingSummary
            :quote="quote"
            :trips="trips"
            :currency-type="currencyType"
            :exchange-rate="exchangeRate"
            :contract-total-amount="contractTotalAmount"
            :cancellation-policy="showCancellationPolicy"
          />
        </div>
      </template>
    </TwoColumnLayout>
    <CharterUpFooter />
  </v-form>
</template>

<script>
import TwoColumnLayout from '@/layouts/TwoColumnLayout.vue'
import CharterUpToolbar from '@/components/CharterUpToolbar.vue'
import CharterUpFooter from '@/components/CharterUpFooter.vue'

import { mask } from 'vue-the-mask'

import { Duration, DateTime } from 'luxon'
import { currencyFilter, pluralize } from '@/utils/string'
import { phoneFormatFilter } from '@/utils/phone'

import TripCollectionCard from '@/components/CharterUpTripCollectionCard.vue'
import ContractBillingSummary from '@/components/CharterUpContractBillingSummary.vue'

import CheckoutMixin from '@/components/CheckoutMixin'

export default {
  components: {
    CharterUpToolbar,
    CharterUpFooter,
    TripCollectionCard,
    ContractBillingSummary,
    TwoColumnLayout,
  },
  filters: {
    phoneFormatFilter,
    itineraryDateFilter(datetime, zone) {
      return DateTime.fromISO(datetime, { zone })
        .toLocaleString(DateTime.DATETIME_SHORT)
        .split(',')?.[0]
    },
    itineraryTimeFilter(datetime, zone) {
      return DateTime.fromISO(datetime, { zone })
        .toLocaleString(DateTime.DATETIME_SHORT)
        .split(',')?.[1]
    },
  },
  directives: {
    mask,
  },
  mixins: [CheckoutMixin],
  metaInfo() {
    this.changeFavicon(require('@/assets/images/icon-charterup.svg'))
    return {
      title: `Contract #${this.quote.managedId || this.quote.quoteId}`,
    }
  },
  props: {
    quote: {
      type: Object,
      default: () => ({
        company: {
          address: {},
        },
        customer: {},
        notes: [],
      }),
    },
    terms: {
      type: String,
      default: '',
    },
    isSubmitting: {
      type: Boolean,
    },
    currencyType: {
      type: String,
      default: '',
    },
    exchangeRate: {
      type: Number,
      default: null,
    },
    contractTotalAmount: {
      type: Number,
      default: null,
    },
    trips: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      formIsValid: false,
      billingAddressFormIsValid: false,
      activePaymentMethod: '',
      availablePaymentMethods: ['credit_card', 'check'],
      agree: false,
      quoteDetailLoading: false,
      firstName: {
        value: '',
        rules: [(v) => !!v || 'First name is required'],
      },
      lastName: {
        value: '',
        rules: [(v) => !!v || 'Last name is required'],
      },
      email: {
        value: '',
        rules: [
          (v) => !!v || 'E-mail is required',
          (v) => /.+@.+/.test(v) || 'E-mail must be valid',
        ],
      },
      phone: {
        value: '',
        mask: '+1 (###) ###-####',
        rules: [(v) => !!v || 'Phone number is required'],
      },
      cardName: {
        value: '',
        rules: [(v) => !!v || 'Name is required'],
      },
      cardNumber: {
        value: '',
        errorMessages: null,
        rules: [
          (v) => !!v || 'Card number is required',
          (v) => v.length >= 15 || 'Card number is not valid',
        ],
      },
      cardExpiration: {
        value: '',
        masks: {
          default: '#/####',
          longer: '##/####',
        },
        errorMessages: null,
        rules: [
          (v) => !!v || 'Card expiration date is required',
          (v) => v.length >= 5 || 'Card expiration is not valid',
        ],
      },
      cardCVV: {
        value: '',
        masks: {
          default: '###',
          visa: '###',
          mastercard: '###',
          discover: '###',
          amex: '####',
          diners: '###',
        },
        errorMessages: null,
        rules: [
          (v) => !!v || 'Card CVV is required',
          (v) => v.length >= 3 || 'Card CVV is not valid',
        ],
      },
      paymentServiceError: '',
      address1: {
        value: '',
        rules: [(v) => !!v || 'Address is required'],
      },
      zip: {
        value: '',
        rules: [
          (v) => !!v || 'Zip code is required',
          (v) => (v.length >= 5 && v.length <= 10) || 'Zip code is not valid',
        ],
      },
      city: {
        value: '',
        rules: [(v) => !!v || 'City is required'],
      },
    }
  },
  computed: {
    showCancellationPolicy() {
      const sevenDays = Duration.fromObject({ days: -14 })
      const dt = DateTime.fromISO(
        this.quote?.trips?.[0]?.stops?.[0]?.pickupDatetime
      ).plus(sevenDays)
      const now = DateTime.local()
      return now < dt
    },
    expirationMonth() {
      if (this.cardExpirationLength === 'longer') {
        return this.cardExpiration.value.substring(0, 2)
      }
      return this.cardExpiration.value.substring(0, 1)
    },
    expirationYear() {
      if (this.cardExpirationLength === 'longer') {
        return this.cardExpiration.value.substring(3)
      }
      return this.cardExpiration.value.substring(2)
    },

    cardNumberMask() {
      if (this.cardType === 'amex') {
        return '#### ###### #####'
      }
      if (this.cardType === 'diners') {
        return '#### ###### ####'
      }
      return '#### #### #### ####'
    },
    cardType() {
      if (this.cardNumber?.value?.[0] === '5') {
        return 'mastercard'
      } else if (this.cardNumber?.value?.[0] === '4') {
        return 'visa'
      } else if (this.cardNumber?.value?.[0] === '6') {
        return 'discover'
      } else if (
        ['34', '37'].includes(this.cardNumber?.value?.substring(0, 2))
      ) {
        return 'amex'
      } else if (
        ['30', '36', '38'].includes(this.cardNumber?.value?.substring(0, 2))
      ) {
        return 'diners'
      } else {
        return 'default'
      }
    },
    cardNumberRawValue() {
      return this.cardNumber.value.split(' ').join('')
    },
    cardExpirationRawValue() {
      return this.cardExpiration.value.split('/').join('')
    },
    cardExpirationLength() {
      if (this.cardExpirationRawValue?.[0] === '0') {
        return 'longer'
      }
      if (this.cardExpirationRawValue?.[0] === '1') {
        if (
          this.cardExpirationRawValue?.[1] === '0' ||
          this.cardExpirationRawValue?.[1] === '1'
        ) {
          return 'longer'
        }
        if (this.cardExpirationRawValue?.[1] === '2') {
          if (this.cardExpirationRawValue?.[2] !== '0') {
            return 'longer'
          }
        }
      }
      return 'default'
    },
    allTripsFullPayment() {
      return (
        this.trips?.filter((trip) => trip.paymentType?.key !== 'full_payment')
          ?.length === 0
      )
    },
    onlyOneTrip() {
      return this.trips?.length === 1
    },
  },
  async created() {
    if (this.paymentMethodAllowed('credit_card')) {
      this.activePaymentMethod = 'credit_card'
    } else if (this.paymentMethodAllowed('check')) {
      this.activePaymentMethod = 'check'
    }
  },
  methods: {
    currencyFilter,
    phoneFormatFilter,
    pluralize,
    changeFavicon(src) {
      const head = document.head || document.getElementsByTagName('head')[0]
      const link = document.createElement('link')
      const oldLink = document.getElementById('dynamic-favicon')
      link.id = 'dynamic-favicon'
      link.rel = 'shortcut icon'
      link.href = src
      if (oldLink) {
        head.removeChild(oldLink)
      }
      head.appendChild(link)
    },
    paymentMethodAllowed(type) {
      return this.quote?.trips[0]?.paymentMethods?.find((method) => {
        return method.isAllowed && method.paymentMethodType?.key === type
      })
    },

    clearErrorMessages() {
      this.cardNumber.errorMessages = null
      this.cardExpiration.errorMessages = null
      this.paymentServiceError = ''
    },
    submit() {
      const userCheckedAgree = this.$refs['accept-checkbox'].validate()

      if (!userCheckedAgree) {
        return
      }

      this.clearErrorMessages()
      this.$emit('submit')
    },
  },
}
</script>

<style lang="scss">
@import '@/scss/charterup/main';

.coachrail .charterup {
  h2 {
    border-bottom: 0 !important;
  }

  .container {
    padding: 24px !important;
    max-width: 1100px !important;
  }

  .v-card {
    border-radius: 5px !important;
    border: 1px solid $border-gray !important;
  }

  .v-btn-toggle {
    border-radius: 5px;
    position: relative;
    top: -8px;
    margin-bottom: 12px;

    .v-btn--active .v-btn__content {
      color: $white !important;
    }
  }

  .unassuming-link {
    color: $gray !important;
    text-decoration-line: underline !important;
  }

  .theme--light.v-text-field--outline > .v-input__control > .v-input__slot {
    border-width: 1px;
    border-color: rgb(230, 232, 234);
    background-color: $white !important;
  }

  .theme--light.v-text-field--outline.v-input--is-focused:not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot {
    border-width: 1px;
    border-color: $primary;
  }

  .theme--light.v-text-field--outline:not(.v-input--is-focused):not(.v-input--has-state)
    > .v-input__control
    > .v-input__slot:hover {
    border-width: 1px;
    border-color: rgb(230, 232, 234);
  }

  .v-text-field__slot input {
    font-family: 'Airbnb Cereal App Book';
  }

  .fade-in {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-duration: 0.35s;
    animation-timing-function: ease-in;
    animation-iteration-count: 1;
  }
}

.coachrail
  .charterup
  .container
  .v-input.v-text-field:not(.v-textarea)
  .v-input__control
  .v-input__slot
  input {
  margin: 0 !important;
}

.coachrail .charterup .container .v-btn {
  font-family: Airbnb Cereal App Book;
  &.primary {
    background-color: rgb(0, 166, 242);
    color: $white;
    .v-btn__content {
      color: $white;
      font-family: Airbnb Cereal App Bold;
    }
  }
  &.button-submit {
    font-size: 24px;
    padding: 0 30px;
    text-transform: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    height: 57px;
    min-width: 101px;
    padding: 0 25.3333333333px;
  }
}

.coachrail
  .charterup
  .container
  .v-btn.primary
  .coachrail
  .charterup
  .container
  .v-text-field--full-width
  .v-input__prepend-outer,
.coachrail
  .charterup
  .container
  .v-text-field--full-width
  .v-input__prepend-inner,
.coachrail
  .charterup
  .container
  .v-text-field--full-width
  .v-input__append-inner,
.coachrail
  .charterup
  .container
  .v-text-field--full-width
  .v-input__append-outer,
.coachrail
  .charterup
  .container
  .v-text-field--enclosed
  .v-input__prepend-outer,
.coachrail
  .charterup
  .container
  .v-text-field--enclosed
  .v-input__prepend-inner,
.coachrail .charterup .container .v-text-field--enclosed .v-input__append-inner,
.coachrail
  .charterup
  .container
  .v-text-field--enclosed
  .v-input__append-outer {
  margin-top: 0px;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
