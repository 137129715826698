<template>
  <v-app>
    <EnvironmentIndicator />
    <TheAlerts />
    <component
      :is="checkoutPageBasedOnCompanyType"
      :quote="contract"
      :trips="trips"
      :terms="terms"
      :is-submitting="isSubmitting"
      :currency-type="currencyType"
      :exchange-rate="exchangeRate"
      :contract-total-amount="contractTotalAmount"
      :payment-gateway="paymentGateway"
      @submit="submit"
    />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex'

import contracts from '@/services/contracts'
import { PaymentGateway } from '@/services/paymentGatewayService'
import exchangeRate from '@/services/exchangeRate'

import TheAlerts from '@/components/TheAlerts.vue'
import CheckoutProviderForm from '@/components/CheckoutProviderForm.vue'
import CheckoutProviderConfirmation from '@/components/CheckoutProviderConfirmation.vue'
import CheckoutProviderPageNotFound from '@/components/CheckoutProviderPageNotFound.vue'
import ContractCheckoutBrokerForm from '@/components/ContractCheckoutBrokerForm.vue'
import ContractCheckoutCharterUpForm from '@/components/ContractCheckoutCharterUpForm.vue'
import EnvironmentIndicator from '@/components/EnvironmentIndicator.vue'

export default {
  metaInfo() {
    return {
      title: `Checkout${
        this.contract && this.contract.company.name
          ? ' - ' + this.contract.company.name
          : ''
      }`,
      titleTemplate: '%s',
    }
  },
  components: {
    EnvironmentIndicator,
    TheAlerts,
    CheckoutProviderForm,
    CheckoutProviderConfirmation,
    CheckoutProviderPageNotFound,
    ContractCheckoutBrokerForm,
    ContractCheckoutCharterUpForm,
  },
  props: {
    hash: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      contract: null,
      terms: '',
      isSubmitting: false,
      quoteNotFound: false,
      trips: [],
      hasSubmittedSuccessfully: false,
      paymentGateway: undefined,
      exchangeRate: null,
      currencyType: 'USD',
      contractTotalAmount: null,
      redirectsForBrokerTypes: {
        shofur: 'https://shofur.com/reservation/thank-you',
        national:
          'https://www.charterup.com/reservation-confirmation?name=National%20Charter%20Bus',
        gogo: 'https://gogocharters.com/thank-you',
      },
    }
  },
  computed: {
    checkoutPageIsForBroker() {
      return !!this.contract?.checkoutPage && this.contract?.company?.broker
    },
    checkoutPageIsForCharterUp() {
      return (
        (!this.contract?.checkoutPage || this.contract?.checkoutPageId === 4) &&
        this.contract?.company?.broker
      )
    },
    checkoutBrokerRedirectType() {
      // if provider
      if (!this.checkoutPageIsForBroker) {
        return null
      }

      if (this.checkoutPageIsForCharterUp) {
        return 'charterup'
      }

      // if shofur, gogo, national
      return this.quote?.checkoutPage?.key
    },
    checkoutPageBasedOnCompanyType() {
      // DEBUG FLAGS
      if (this.$route.query.showConfirmation) {
        return CheckoutProviderConfirmation
      }
      if (this.hasSubmittedSuccessfully) {
        return CheckoutProviderConfirmation
      }
      if (this.quoteNotFound) {
        return CheckoutProviderPageNotFound
      }
      if (this.checkoutPageIsForCharterUp) {
        return ContractCheckoutCharterUpForm
      }
      if (this.checkoutPageIsForBroker) {
        return ContractCheckoutBrokerForm
      }
      if (this.contract) {
        return CheckoutProviderForm
      }
      return null
    },
  },
  async created() {
    try {
      const quoteResponse = await contracts.checkoutByHash(this.hash)

      if (quoteResponse.status === 404) {
        this.quoteNotFound = true
        return
      }
      this.contract = quoteResponse?.data?.contract
      this.trips = quoteResponse?.data?.trips
      this.contractTotalAmount = quoteResponse?.data?.contractTotalAmount
      const companyId = this.contract?.company.companyId
      this.terms = this.contract.companyTerm.body
      this.currencyType = this.contract.currencyType
      if (this.currencyType !== 'USD') {
        const exchangeRateData = await exchangeRate
          .getExchangeRate()
          .catch((e) => e)
        this.exchangeRate = exchangeRateData?.data?.rates?.[this.currencyType]
      }
      const companyCheckoutPageId = this.contract?.checkoutPage?.checkoutPageId
      const paymentGateway = new PaymentGateway()
      const paymentGatewayResponse = await paymentGateway.init(
        { id: companyId },
        companyCheckoutPageId
      )
      this.paymentGateway = paymentGatewayResponse?.paymentGateway

      // add processing fee of 0 if there is none
      this.contract.trips.forEach((trip, tripIndex) => {
        if (
          !trip.charges.some((charge) => {
            return charge.chargeType.key === 'processing_fees'
          })
        ) {
          trip.charges.push({
            amount: 0.0,
            chargeType: {
              description: 'Miscellaneous Fees',
              key: 'processing_fees',
              label: 'Processing Fees',
            },
          })
        }
      })
    } catch (error) {
      const host = window.location.host
      if (host.includes('shofur')) {
        window.location = 'https://www.shofur.com/checkout-page-not-found'
      } else if (host.includes('gogocharters')) {
        window.location = 'https://www.gogocharters.com/checkout-page-not-found'
      } else if (host.includes('nationalbuscharter')) {
        window.location =
          'https://www.nationalbuscharter.com/checkout-page-not-found'
      } else {
        this.quoteNotFound = true
      }
    }
  },
  methods: {
    ...mapActions({
      showAlert: 'app/showAlert',
    }),

    async submit() {
      this.isSubmitting = true

      try {
        await contracts.markContractSigned(this.contract.quoteId)
        if (this.checkoutPageIsForBroker) {
          if (this.checkoutPageIsForCharterUp) {
            return
          }
          window.location = this.redirectsForBrokerTypes[
            this.checkoutBrokerRedirectType
          ]
          return
        }
        this.hasSubmittedSuccessfully = true
      } catch (error) {
        throw new Error(error)
      }
    },
  },
}
</script>

<style lang="scss">
body,
#app {
  background: $white !important;
}
</style>
